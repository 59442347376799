import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import APP_UTILITIES from "@/utilities/commonFunctions";
import API_CONST from '@/constants/ApiConst';

@Component({
    components: {
        vueDropzone: vue2Dropzone
    }
})
export default class UploadFile extends Vue {
    private objScreenText: ScreenText = new ScreenText();

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }
    

    @Prop()
    scholerIds!:any;

    @Prop()
    panelTitle!:any;

    @Prop()
    allFilesArray!:any;
    
    public maxFilesize: number = 5242880 
    public totalFilesize: number = 0;
    public maxFileSizeFlag: boolean = false;
    public isCsvFileUploaded: boolean = false;
    public isFileSelected: boolean = false;
    public isFileCorrect: boolean = true;
    public currentCsvFileName: string = "";
    public newFileName: string = ""
    public userSelectedFile: any
    public isSendingServer: any = false
    public uploadedFileArray: any = [];
    public progressValue: any = 0;
    public maxprogressValue: any = 100;
    public checkFileOption: string = 'upload'
    public userRoles: any = [];
    public siteId: any = '';
    public   iterator :any
     public maximumFile=false
     private filterErrorFile:Array<any>=[]

    public dropzoneOptions: any = {
        method: 'POST',
     
        url: `${process.env.VUE_APP_BASE_ENROLLMENT_URL}${API_CONST.UPLOAD_CSV}?siteId=` + this.getSiteId(),
      
        maxFilesize: 5,
        maxFiles: 5,
        autoProcessQueue: false,
        autoQueue: false,
        headers: {
            'Accept': '*/*',
            'Authorization': `Bearer ${APP_UTILITIES.getCookie('auth_token')}`
        },
        includeStyling: false,
        chunkSize: 500,
        addRemoveLinks: true,
      
       acceptedFiles: '.jpeg,.pdf,.txt,image/*,text/plain,application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
       clickable: true,
        timeout: 180000,
    
    }
    beforeMount() {
        this.uploadedFileArray = this.allFilesArray
    }

     * fileGenrator(files:any ) {
        let i = 0;
        for (let value of files) {
            this.fileAdded(files[i])
            if(i>=4){
              break;
            }
           yield value;
             i++;
         
        }
      }

    vdropzonefilesadded(files: any){
        this.filterErrorFile=[]
        let finalLength=files.length+this.uploadedFileArray.length
        if(finalLength<=5){
            this.maximumFile=false
            let filterFile=this.filterErrorFiles(files).filterFile
            
             if(files.length>1){
                let filterErrorFile=this.filterErrorFiles(files).filterErrorFile
                this.filterErrorFile=filterErrorFile
                if(filterFile.length==0){
                    this.showError()
                    return
                }
                this.iterator = this.fileGenrator(filterFile);
             }else{
                this.iterator = this.fileGenrator(files);
             }
            this.iterator.next()
        
        }else{
            this.maximumFile=true
            this.maxFileSizeFlag = false;
            this.isCsvFileUploaded = false;
            this.isFileSelected = false;
            this.isFileCorrect = true;
            this.removeAllFiles();   
        } 
    }
    filterErrorFiles(files:Array<any>){
        let filterErrorFile:Array<any>=[]
        let filterFile:Array<any>=[]
        for(let file=0;file<files.length;file++){
        if(this.checkFileType(this.getFileNameAndtype(files[file].name).ext) && files[file].size <= this.maxFilesize){
            filterFile.push(files[file])
        }else{
            filterErrorFile.push(files[file])
        }
     }
    
     return { filterErrorFile,filterFile}
    }
    fileAdded(file: any) {
        if(this.checkFileType(this.getFileNameAndtype(file.name).ext)){
        this.checkFileOption ="upload"
        this.newFileName=""
        this.totalFilesize = 0;
        this.progressValue = 0
        this.maxprogressValue =100;
        this.totalFilesize = this.totalFilesize + file.size;
        if (this.totalFilesize <= this.maxFilesize) {
            this.maxFileSizeFlag = true;
        } else {
            this.isCsvFileUploaded = false;
            this.isFileSelected = true;
            this.maxFileSizeFlag = false;
            this.removeAllFiles();
            return
       
        }
        this.userSelectedFile = file;
        if (this.checkDuplicateFile(file)) {
            this.isCsvFileUploaded = false;
            this.isFileSelected = false;
            this.removeAllFiles();
            this.removeCurrentSelection()
            this.$emit('openFileDailog');

        } else {
            this.uploadFile()
         
        }
   
    }
    else{
     this.maxFileSizeFlag=true
     this.isCsvFileUploaded=false
     this.isFileSelected = true;
     this.isFileCorrect = false;
    
   
    
    }


    }
    checkFileType(ext:string){
        let fileType=['jpg','.jpeg','png','gif','pdf','txt','application/pdf','doc','docx','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document']  
        return fileType.some((filetype: any) => {
          return filetype.toLowerCase()==ext.toLowerCase()
       })
       }
     uploadFile() {
      
        this.isCsvFileUploaded = false;
        this.isFileSelected = true;
        this.isFileCorrect = true;
        this.maxFileSizeFlag = true;
          this.fileSave()
        
    }
    checkDuplicateFile(file:any){
        return this.uploadedFileArray.some((fileobj: any) => {
            return this.replaceWhiteSpaceFromFileName1(file.name) == this.replaceWhiteSpaceFromFileName1(fileobj.fileName)
        })
    }
    getFileNameAndtype(name: any) {
        const lastDot = name.lastIndexOf('.');
        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);
        return { fileName: fileName, ext: ext }
    }

    renameFile(filesArray: any) {
        var newFilearray=new Array();  
        var tempArray=new Array(); 
        for (var r in filesArray)
        {
            if(tempArray.indexOf(this.replaceWhiteSpaceFromFileName1(filesArray[r].fileName))>-1)
            {     
            var index=1;
            while(tempArray.indexOf(this.replaceWhiteSpaceFromFileName1(filesArray[r].fileNameWithoutType)+'('+index+')'+'.'+this.getFileNameAndtype(filesArray[r].fileName).ext)>-1)
            {
            index++;      
            }
            var str=filesArray[r].fileNameWithoutType+'('+index+')'; 
            newFilearray.push(str);
                tempArray.push(this.replaceWhiteSpaceFromFileName1(str));
            }
            else
            {
            newFilearray.push(filesArray[r].fileName);
            tempArray.push(this.replaceWhiteSpaceFromFileName1(filesArray[r].fileName));
            
            }
        }
        return newFilearray
    };

    fileSave() {
       
        this.isSendingServer = true
      
        var formData = new FormData();
        if (this.newFileName.length > 0 && this.checkFileOption == "keepBoth") {
            formData.append('formFile', this.userSelectedFile, this.newFileName);
        } else {
            formData.append('formFile', this.userSelectedFile,this.replaceWhiteSpaceFromFileName(this.userSelectedFile.name));
        }
        let scholarId=this.panelTitle=='Edit'?this.scholerIds.id:0
        var xhr = new XMLHttpRequest();
        xhr.open('POST', `${process.env.VUE_APP_BASE_URL}${API_CONST.UPLOAD_DOCUMENTS}/site/`+this.getSiteId()+'/scholar/'+scholarId, true);
        xhr.setRequestHeader("Accept", '*/*');
        xhr.setRequestHeader("Authorization", `Bearer ${APP_UTILITIES.getCookie('auth_token')}`);
        xhr.onload = ()=> {
            if (xhr.status === 200) {
                var resp = JSON.parse(xhr.response);
                this.fileSaveServerResponce(200, resp)
                if(this.uploadedFileArray.length<5){
               let fileIsDone=this.iterator.next().done
                if(fileIsDone){
                 this.showError()
                }
               }
                
               
            } else {
                var errorResp = JSON.parse(xhr.response);
                this.fileSaveServerResponce(400, errorResp)
                this.iterator.next()
            }
        };
        xhr.onerror =  ()=> {
            var resp = JSON.parse(xhr.response);
            this.fileSaveServerResponce(400, resp)
            this.iterator.next()
        };
        if (xhr.upload) {
            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    this.progressValue = e.loaded
                    this.maxprogressValue = e.total;
                }
            }
            xhr.upload.onloadstart = (e) => {
                this.progressValue = 0

            }
            xhr.upload.onloadend = (e) => {
                this.progressValue = e.loaded
            }
        }
        xhr.send(formData);
    
    }
  showError(){
    if(this.filterErrorFile.length>0){
        if(!this.checkFileType(this.getFileNameAndtype(this.filterErrorFile[0].name).ext)){
            this.maxFileSizeFlag=true
            this.isCsvFileUploaded=false
            this.isFileSelected = true;
            this.isFileCorrect = false;
        }else{
          this.isCsvFileUploaded = false;
          this.isFileSelected = true;
          this.maxFileSizeFlag = false; 
        }
       }
  }
     fileSaveServerResponce(type: any, response: any){
        this.isSendingServer = false
        if (type == 200) {
            this.isCsvFileUploaded = false;
            this.isFileSelected = false;
            this.removeAllFiles();
            this.removeCurrentSelection()
            if (this.checkFileOption == "keepBoth") {
                this.uploadedFileArray.push({ id: response.fileId, fileName: this.newFileName, fileNameWithoutType: this.getFileNameAndtype(this.newFileName).fileName })
            }
            else {
                if (this.checkDuplicateFile(this.userSelectedFile)) {
                    let findIndex= this.uploadedFileArray.findIndex((fileObj:any)=>this.getFileNameAndtype(this.replaceWhiteSpaceFromFileName(this.userSelectedFile.name)).fileName == fileObj.fileNameWithoutType)
                     if(findIndex!=-1){
                        this.uploadedFileArray[findIndex].id=response.fileId
                        this.uploadedFileArray[findIndex].fileName= this.replaceWhiteSpaceFromFileName(this.userSelectedFile.name)
                        this.uploadedFileArray[findIndex].fileNameWithoutType= this.getFileNameAndtype(this.replaceWhiteSpaceFromFileName(this.userSelectedFile.name)).fileName
                     }
                }else{
                    this.uploadedFileArray.push({ id: response.fileId, fileName: this.replaceWhiteSpaceFromFileName(this.userSelectedFile.name), fileNameWithoutType: this.getFileNameAndtype(this.replaceWhiteSpaceFromFileName(this.userSelectedFile.name)).fileName }) 
                }
            }
            this.$emit('addFileToView', this.uploadedFileArray);

        } else {
            if (response === this.getScreenText('DZ_ERROR_FILE_TYPE')) {
                this.isFileCorrect = false;
                this.isFileSelected=true
                this.maxFileSizeFlag=true
                this.isCsvFileUploaded=false
                this.removeAllFiles();
            } else {
                this.maxFileSizeFlag = false;
                this.removeAllFiles();
                this.isFileCorrect = false;
            }
        }
     }

    removeCurrentSelection() {
        this.isCsvFileUploaded = false;
        this.isFileSelected = false;
        this.maxFileSizeFlag = false;
        this.maximumFile=false
        this.isFileCorrect = true;


    }
    removeAllFiles() {
        let csvInstance: any = this.$refs.myVueDropzone1;
        if(csvInstance){
        csvInstance.removeAllFiles(true);
        }
    }

    public getSiteId() {
        let sID = 0;
        let userRoles: any = APP_UTILITIES.getCookie("user_role");
        this.userRoles = JSON.parse(userRoles);
        this.userRoles.forEach((item: any, index: number) => {
            if (item.hasOwnProperty("siteId")) {
                sID = item.siteId;
            }
        })
        return this.siteId = sID
    }
    deleteFileHandler(fileObj: any,type:any) {
        let skipDeleteFile: any = this.uploadedFileArray.filter((file: any) => file.id !== fileObj.id)
        this.uploadedFileArray=skipDeleteFile;
        this.$emit('addFileToView', skipDeleteFile);
        
    }
    replaceFileHandler() {
        this.checkFileOption = 'replace'
        this.newFileName = ''
        this.uploadFile()
    }
    bothFileHandler() {
        this.checkFileOption = 'keepBoth'
        let tempArray = [...this.uploadedFileArray, { fileName: this.replaceWhiteSpaceFromFileName(this.userSelectedFile.name), fileNameWithoutType: this.getFileNameAndtype(this.replaceWhiteSpaceFromFileName(this.userSelectedFile.name)).fileName }]
        this.newFileName = this.renameFile(tempArray)[this.renameFile(tempArray).length - 1] + '.' + this.getFileNameAndtype(this.userSelectedFile.name).ext
        this.uploadFile()
    }
    replaceWhiteSpaceFromFileName(name:any){
        return name
    }
    replaceWhiteSpaceFromFileName1(name:any){
        if(name){
         return name.replace(/\s/g, '') 
        }
        return ''
    }
    downLoadFile(file: any) {
        let scholarId = this.panelTitle == 'Edit' ? this.scholerIds.id : 0
        fetch(`${process.env.VUE_APP_BASE_URL}${API_CONST.DOWNLOAD_DOCUMENTS}/` + file.id + "/scholar/" + scholarId + "/site/" + this.getSiteId(), {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${APP_UTILITIES.getCookie('auth_token')}`,

            }

        }).then(function (t) {

            return t.blob().then((b) => {

                var a = document.createElement("a");

                a.href = URL.createObjectURL(b);

                a.setAttribute("download", file.fileName);

                a.click();

            }

            );

        });

    }
    
}